@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  max-width: 100% !important;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  max-width: 100% !important;
  height: 100%;
}

.main {
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  color: #0A0A0A;
}

.flex {
  display: flex;
  align-items: center;
}

.together {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.separate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
}

.spread-evenly {
  display: flex;
  justify-content: space-evenly;
}

.break {
  height: 1em;
}

.left {
  display: flex;
  justify-content: left;
  gap: 0.5em;
  padding: 0.5em;
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

.fill {
  object-fit: fill;
}

.bold {
  font-weight: bold;
}
/* COLORS */

.color1 {
  color: #0A0A0A
}

.color2 {
  color: #FEFEFE;
}

.color3 {
  color: #585757;
}

.color4 {
  color: #89c353;
}

.color5 {
  color: #d3f8b0;
}

.bg-pearl {
  background-color: #E9E7E9;
}

.bg1 {
  background-color: #0A0A0A;
}

.bg2 {
  background-color: #FEFEFE;
}

.bg3 {
  background-color: #e0e0ed;
}

.bg4 {
  background-color: #F6F6F8;
}

.bg5 {
  background-color: rgba(0, 0, 0, 0.762);
}

.bg6 {
  background-color: #e0e2e46b;
}

.bg7 {
  background-color: #89c353;
}

.bg8 {
  background-color: #d3f8b0;
}

.bg9 {
  background-color: #e8efe7;
}

.no-bg {
  background-color: rgba(0, 0, 0, 0) !important;
}
.bg-red {
  background-color: red;
}

.dark {
  color: #0A0A0A;
}

.light {
  color: #FEFEFE;
}

.red {
  color: red;
}

.blue {
  color: #0f0fa2;
}

.green {
  color: #0dc30d
}

/*  */

.font1 {
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Raleway', sans-serif; */
  /* font-family: 'Rubik', sans-serif; */
  /* font-family: 'Playfair Display', serif; */
  font-family: 'Lora', serif;
}

.font2 {
  font-family: 'Poppins', sans-serif;
}

.font3 {}

/*  */

.border1 {
  border: 1px solid #FEFEFE;
}

.border2 {
  border: 1px solid #0a0a0a;
}

.border3 {
  border: 1px solid #c2c2cb;
}

.no-border {
  border: 0;
}

.border-top1 {
  border: 0;
  border-top: 1px solid #0a0a0a;
}

.border-bottom1 {
  border: 0;
  border-bottom: 1px solid #0A0A0A;
}

.border-vert1 {
  border: 0;
  border-top: 1px solid #0A0A0A;
  border-bottom: 1px solid #0A0A0A;
}

.border-red {
  border: 1px solid red;
}

.border-green {
  border: 1px solid #89c353;
}

/*  */
.divider {
  border: 0;
  border-bottom: 1px solid #89c353;
  width: 60%;
  margin: 1em 0;
}

.padding {
  padding: 1em;
}

.relative {
  position: relative;
}

/*  */
.black-out {
  position: fixed;
  background-color: #0a0a0aa6;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.fixed {
  position: fixed;
  bottom: 0;
}


@media only screen and (min-width: 350px) {
  .top {
    padding: 1em;
    display: flex;
    justify-content: space-between;
  }

  .top img {
    width: 18vw;
  }

  .top-icon {
    font-size: 2em;
  }

  .page-title {
    font-weight: 600;
    font-size: 3.4em;
    letter-spacing: -3px;
    color: #89c353;
  }

  .page-subtitle {
    font-size: 1.2em;
    font-weight: 300;
    letter-spacing: -1px;
    padding: 0.5em
  }

  .page-title-sm {
    font-size: 2.3em;
    text-align: left;
    letter-spacing: -1px;
  }

  .page-cap {
    font-size: 2em;
    font-weight: 600;
    letter-spacing: -1px;
  }
}

@media only screen and (min-width: 600px) {
  .top img {
    width: 10vw;
  }

  .top-icon {
    font-size: 1.8em;
  }

  .page-title {
    text-align: center;
  }

  .page-subtitle {
    text-align: center;
    font-size: 1.3em;
    padding: 1.5em;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 800px) {
  .top img {
    width: 7vw;
  }

  .top-icon {
    font-size: 1.6em;
  }

  .page-title {
    font-size: 4em;
  }

  .page-subtitle {
    font-size: 1.4em;
    width: 85%;
  }
}

@media only screen and (min-width: 1000px) {
  .top img {
    width: 6vw;
  }

  .top-icon {
    font-size: 1.8em;
  }

  .page-title {
    font-size: 4.5em;
    line-height: 80px;
  }

  .page-subtitle {
    font-size: 1.6em;
    width: 80%;
  }
}

@media only screen and (min-width: 1200px) {
  .top img {
    width: 5vw;
  }

  .top-icon {
    font-size: 2em;
  }

  .page-title {
    font-size: 5em;
  }

  .page-subtitle {
    width: 75%;
  }
}