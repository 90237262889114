/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .modal {
        z-index: 100;
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: #0a0a0aba;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    .modal-wrap {
        width: 90vw;
        margin: 0 auto;
        margin-top: 35vh;
        padding: 1em;
        text-align: center;
    }
    .modal-wrap h1 {
        line-height: 30px;
    }
    .modal-wrap p {
        padding: 1em 0;
        font-weight: 300;
    }
    .modal-icon {
        font-size: 3em;
    }
}

@media only screen and (min-width: 600px) {
    .modal-wrap {
        padding: 1em;
        width: 450px;
    }
}

@media only screen and (min-width: 800px) {}

@media only screen and (min-width: 1000px) {}

@media only screen and (min-width: 1200px) {}