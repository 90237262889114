.loading-wrap {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.737);
    z-index: 1000;
    top: 0;
    left: 0;
}
.loading {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 2em;
    margin: 0 auto;
    width: fit-content;
    transform: translateY(50vh);
}