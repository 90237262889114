/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .gallery {
        padding: 1em;
    }
    .gallery-wrap {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 1em;
        gap: 1em;
    }
    .gallery-img {
        height: 50vh;
        width: 100%;
        object-fit: cover;
    }
}

@media only screen and (min-width: 600px) {
    .gallery-wrap {
        grid-template-columns: 1fr 1fr;
    }
    .gallery-img {
        height: 60vh;
    }
}

@media only screen and (min-width: 800px) {}

@media only screen and (min-width: 1000px) {
    .gallery-wrap {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .gallery-img {
        height: 48vh;
    }
}

@media only screen and (min-width: 1200px) {
    .gallery-wrap {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}