/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .blog {
        padding: 1em;
    }
    .blog-wrap {
        padding: 1em 0;
    }
    .blog-info {
        font-size: 1.2em;
        font-weight: 300;
        line-height: 20px;
    }
    .first-blog-img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }
    .first-blog-text {
        padding: 1em 0;
    }
    .first-blog-date {
        font-size: 0.8em;
        color: gray;
    }
    .first-blog-title {
        font-size: 1.3em;
        line-height: 20px;
        letter-spacing: -1px;
    }
    .first-blog-desc {
        font-size: 0.8em;
        font-weight: 300;
        padding: 0.5em 0;
    }
    .first-blog-tags {
        display: flex;
        gap: 0.6em;
    }
    .first-blog-tag {
        padding: 0.5em 1.4em;
        border-radius: 20px;
        font-size: 0.7em;
    }
    .blog-img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }
    .blog-text {
        padding: 1em 0;
    }
    .blog-date {
        font-size: 0.8em;
        color: gray;
    }
    .blog-title {
        font-size: 1.3em;
        line-height: 20px;
        letter-spacing: -1px;
    }
    .blog-desc {
        font-size: 0.8em;
        font-weight: 300;
        padding: 0.5em 0;
    }
    .blog-tags {
        display: flex;
        gap: 0.6em;
    }
    .blog-tag {
        padding: 0.5em 1.4em;
        border-radius: 20px;
        font-size: 0.7em;
    }
}

@media only screen and (min-width: 600px) {
    .first-blog-text {
        width: 60vw;
    }
    .blog-right {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
    }
    .blog-img {
        height: 40vh;
    }
}

@media only screen and (min-width: 800px) {
    .blog-wrap {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1em;   
    }
    .first-blog-title {
        line-height: 26px;
        font-size: 1.8em;
        padding: 0.3em 0;
    }
    .first-blog-text {
        width: 80%;
    }
    .first-blog-desc {
        font-size: 0.9em;
    }
    .blog-right {
        grid-template-columns: 1fr;
        max-height: 80vh;
        overflow-y: scroll;
    }
    .blog-title {
        padding: 0.3em 0;
    }
}

@media only screen and (min-width: 1000px) {
    .first-blog-img {
        height: 60vh;
    }
}

@media only screen and (min-width: 1200px) {
    .blog-wrap {
        grid-template-columns: 55% 45%;
        padding: 3em;
    }
    .blog-right {
        grid-template-columns: 1fr 1fr;
        padding: 1em;
    }
    .blog-img {
        height: 35vh;
    }
}