@media only screen and (min-width: 350px) {
    .services {
        padding: 1em;
    }

    .services-split {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .services-left {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em;
    }

    .service-block {
        padding: 1em;
    }

    .service-block h2 {
        letter-spacing: -1px;
        font-weight: 400;
        font-size: 1.5em;
        padding: 0.5em 0;
    }

    .service-block-desc {
        font-size: 0.9em;
        font-weight: 300;
        padding: 1em 0;
    }

    .service-block-deets {
        font-size: 1.2em;
        line-height: 20px;
        padding: 0.8em 0;
        font-weight: 300;
    }

    .service-btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 0.8em;
        padding: 0.8em 3em;
        border-radius: 3px;
        margin: 0.5em 0;
    }

    .services-right p {
        font-size: 1.5em;
        font-weight: 300;
        letter-spacing: -1px;
    }

    .service-img {
        object-fit: cover;
        height: 200px;
        width: 100%;
        margin-bottom: 0.8em;
    }
}

@media only screen and (min-width: 600px) {
    .services-left {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 800px) {
    .services-left {
        width: 80%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1000px) {
    .services-split {
        grid-template-columns: 1fr 3fr;
    }
    .services-left {
        width: 100%;
        height: 70vh;
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 1200px) {
    .services-left {
        grid-template-columns: repeat(3, 1fr);
    }
}